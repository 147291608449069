import React from "react";
import Footer from "../Components/common/Footer";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import { Link } from "react-router-dom";
import Card from "../Components/common/blogs/Card";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
const Analysis = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02248.png"
        BannerTitle="Updates and Analysis"
        BannerText="
        We have a proficient team of masterful legal advisors and advocates to find you rigid and legitimate solutions. Our analytical team researches and then figures out the perfect strategy.
        "
      />
      {/* <div className="grid md:grid-cols-5  grid-col-2  justify-between items-center px-10  py-20 gap-10 mx-auto">
        <div className="col-span-3   space-y-3">
          <p className="text-3xl font-semibold capitalize font-sans text-secondary">
            The Best Data analysis teams
          </p>
          <p className="uppercase text-5xl text-primary py-1 ">See our work!</p>
          <p className="text-xl font-light text-slate-500 ">
            We carry a pasion for performance marketing and have a knack for
            untangling even the toughest of knots.
          </p>
          <div className="w-28 h-1 bg-gray-500"></div>
        </div>
        <div className="col-span-2  md:relative ">
          <div className=" w-64 shadow-slate-400 shadow-sm p-4 rounded md:absolute left-0 top-0 bg-secondary">
            <h3 className="text-base  font-semibold text-primary ">
            Believe in what you study.
            </h3>
            <p className="text-sm  font-normal text-[#c9bfb1da]">
            Good research can only lead you to good results, as it establishes the roots of the whole.
            </p>
          </div>
          <div className=" w-64 shadow-slate-400 shadow-sm p-4 rounded md:absolute right-0 bottom-0 bg-[#c9bfb1da]">
            <h3 className="text-base   font-semibold text-secondary ">
            Nothing is black and white.
            </h3>
            <p className="text-sm   font-normal text-secondary">
            In the world of judgement, facts are hidden and surpassed by the outcomes of the analysis.
            </p>
          </div>
          <div className="w-20 h-3 bg-secondary absolute right-32 -z-10 top-[5.8rem]"></div>
          <div className="w-12 h-3 bg-primary absolute right-40 -z-10 top-[6.5rem]"></div>
        </div>
      </div> */}

      <div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2  grid-cols-1 px-10 mx-auto gap-6 pt-20">
          {" "}
          <Card
            Image="/assets/images/blog/blogone.png"
            Title=" We are delighted to welcome on board Capt. Sanjay Gahlot (IRS)(R)"
            Description="We are delighted to welcome on board Capt.  Sanjay Gahlot (IRS)(R) as a senior advisor with AP &..."
            Date="26/10/2021"
            url="/we-are-delighted-to-welcome-on-board-capt-sanjay-gahlot-irsr"
          />
          <Card
            Image="/assets/images/blog/blogtwo.png"
            Title=" Our partner Sandeep Das has been recognized as one of India’s top disputes lawyers"
            Description="Happy to share that our partner Sandeep Das has been recognized as one of India’s top disputes ..."
            Date="12/08/2021"
            url="/Our-partner-Sandeep-Das-has-been-recognized-as-one-of-Indias-top-disputes-lawyers"
          />
          <RecentBlogs />
        </div>
        <div className="grid lg:grid-cols-3 px-10  md:grid-cols-2  grid-cols-1 gap-8 pt-20 mx-auto">
          <Card
            Image="/assets/images/blog/blogthree.png"
            Title="AP & Partners have been recognized as a notable firm by asialaw"
            Description="We are pleased to share that AP & Partners has been recognized as a notable firm for #corporate ..."
            Date="30/09/2021"
            url="/AP-Partners-have-been-recognized-as-a-notable-firm-by-asialaw"
          />
          <Card
            Image="/assets/images/blog/blog4.jpeg"
            Title="Our partner Arjun Sinha will be sharing insights on music licensing deal"
            Description="AP & Partners, renowned advisors on regulation regarding media, broadcasting, logistics and tech ..."
            Date="24/09/2021"
            url="/Our-partner-Arjun-Sinha-will-be-sharing-insights-on-music-licensing-deal"
          />
          <Card
            Image="/assets/images/blog/blogfive.png"
            Title="AP & Partners have been recognized once again as a notable firm by IFLR1000 "
            Description="We are pleased to share that AP & Partners has been recognized once again as a notable firm for� ..."
            Date="16-09-2021"
            url="/AP-Partners-have-been-recognized-once-again-as-a-notable-firm-by-IFLR1000"
          />
        </div>
        <div className="grid lg:grid-cols-3  gap-8 md:grid-cols-2 grid-cols-1 px-10 py-20 mx-auto ">
          <Card
            Image="/assets/images/blog/blogsix.png"
            Title="E-commerce rules cast cloud over Amazon, Walmart and local rivals"
            Description="New Delhi: New e-commerce rules will raise costs for all online retailers but pasticularly Amazon an ..."
            Date="22/06/2021"
            url="/E-commerce-rules-cast-cloud-over-Amazon-Walmart-and-local-rivals"
          />
          <Card
            Image="/assets/images/blog/blogseven.png"
            Title="AP & Partners recognized as an award-winning law firm by IBLJ"
            Description="Happy to share that we have been recognized by the India Business Law Journal as an award winning ..."
            Date="07/06/2021"
            url="/AP-Partners-recognized-as-an-award-winning-law-firm-by-IBLJ"
          />
          <Card
            Image="/assets/images/blog/blogeight.png"
            Title="India’s New Tech Laws for Social Media"
            Description="Our Partner, Arjun Sinha speaks to Mugdha Variyar CNBC-TV18 on the new tech laws for social media ac ..."
            Date="04-03-2021"
            url="/Indias-New-Tech-Laws-for-Social-Media"
          />
        </div>
      </div>

      <QuoteSlider />

      {/* <div className="max-w-[900px] mx-auto flex gap-4 px-5 md:px-0 py-16">
        <div className="text-primary text-5xl">
          <img src="/assets/images/quotation.png" alt="quotes" width={120} />
        </div>
        <div className=" text-2xl text-secondary  pt-10">
          AP & Partners is a strong partner for anyone looking to understand and
          navigate the Indian market..
        </div>
      </div> */}

      <div className="mt-10">
        <h2 className="md:text-4xl text-2xl   text-secondary text-center font-medium">
          Rankings and Recognitions
        </h2>
        <div className="h-1 w-24 bg-primary mx-auto mt-4"></div>
        <div className="max-w-3xl mx-auto grid grid-cols-2 md:grid-cols-4 justify-items-center items-center py-10 space-y-4">
          <img src="/assets/images/rank5.png" alt="/" className="w-36" />
          <img src="/assets/images/rank3.png" alt="/" className="w-24" />
          <img src="/assets/images/rank2.jpg" alt="/" className="w-28" />
          <img src="/assets/images/rank1.png" alt="/" className="w-24 pt-4" />
        </div>
        <div className="max-w-2xl mx-auto grid grid-cols-2 md:grid-cols-3 justify-items-center items-center py-10 space-y-4">
          <img src="/assets/images/rank4.jpg" alt="/" className="w-28" />
          <img src="/assets/images/ranking-3.jpg" alt="/" className="w-36" />
          <img src="/assets/images/rank6.jpg" alt="/" className="w-28 pt-4" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Analysis;
