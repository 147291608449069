import { Link } from "react-router-dom";
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Disclosure } from "@headlessui/react";
import { RiArrowDropDownLine, RiArrowRightSLine } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";

const Navbar = () => {
  // setting mobile nav
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  // change nav color when scrolling

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  // close menu on click
  const closeMenu = () => setClick(false);

  return (
    <div
      className={
        color
          ? "header bg-white  z-50 spacingYtext rajez spacingYlogo"
          : "header  bg-white bg-opacity-80 rajez"
      }
    >
      <nav className="navbar w-[100%]">
        <div>
          <Link to="/" className="logo">
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="cursor-pointer spacingYlogo spacinglogo drop-shadow-2xl"
            />
          </Link>
        </div>
        <div>
          <div className="hamburger" onClick={handleClick}>
            {click ? (
              <FaTimes size={30} style={{ color: "#000000" }} />
            ) : (
              <FaBars size={30} style={{ color: "#000000" }} />
            )}
          </div>
          <ul
            id="col"
            className={
              click ? "nav-menu spacingYtext spacingtext active" : "nav-menu"
            }
          >
            <li className="nav-item text-black hover:text-secondary cursor-pointer ">
              <Link to="/" onClick={closeMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item text-black hover:text-secondary cursor-pointer ">
              <Link to="/about" onClick={closeMenu}>
                About Us
              </Link>
            </li>
            <li className="lg:block hidden relative nav-item md:text-black text-white group dropdown cursor-pointer px-8 hover:text-secondary font-bold  tracking-wide">
              Practice Areas
              <div className="hidden group-hover:flex group-hover:flex-col mt-2 py-4 absolute bg-[#DCDCDC] text-black duration-500 transition-all max-w-max rounded w-[250px]">
                <Link to="/corporate" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  cursor-pointer text-black max-w-max m-2 rounded">
                    Corporate
                  </div>
                </Link>
                <Link to="/disputes" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Dispute Resolution
                  </div>{" "}
                </Link>
                <Link to="/employment-benefits" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Employment
                  </div>{" "}
                </Link>
                {/* <Link to="/litigation" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Litigation,Arbitration And Investigation
                  </div>{" "}
                </Link> */}
                <Link to="/special-situations" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Special Situations
                  </div>{" "}
                </Link>
                <Link to="/tmt" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Technology, Media, and Telecommunications
                  </div>{" "}
                </Link>
              </div>
            </li>
            <li className="lg:hidden block text-white pt-3 ">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="text-xl pt-2  flex items-center justify-items-center mx-auto">
                      Practice Areas{" "}
                      <RiArrowRightSLine
                        className={
                          open
                            ? "rotate-90 transform w-7 h-7 mt-1"
                            : "w-7 h-7 mt-1 "
                        }
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/corporate">
                        {" "}
                        <div className="border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Corporate
                        </div>
                      </Link>
                    </Disclosure.Panel>

                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/disputes">
                        {" "}
                        <div className="border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Dispute Resolution
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/employment-benefits">
                        {" "}
                        <div className=" border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Employment
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/special-situations">
                        {" "}
                        <div className="border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Special situations
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/tmt">
                        {" "}
                        <div className=" border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Technology, Media, and Telecommunications
                        </div>
                      </Link>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </li>
            <li className="lg:block hidden group relative nav-item md:text-black text-white group   dropdown cursor-pointer px-8 hover:text-secondary font-bold  tracking-wide">
              Sectors
              <div className="hidden group-hover:flex group-hover:flex-col mt-2 py-4 absolute h-[530px] scroller   bg-[#DCDCDC] text-black duration-500 transition-all max-w-max rounded w-[250px]">
                <Link to="/automotive" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  cursor-pointer text-black max-w-max m-2 rounded">
                    Automotives
                  </div>
                </Link>
                <Link to="/consumer-retail" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Consumer and Retail
                  </div>{" "}
                </Link>
                <Link to="/education" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Education
                  </div>{" "}
                </Link>
                <Link to="/energy-and-natural-resources" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Energy and Natural Resources
                  </div>{" "}
                </Link>
                <Link to="/technology" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Emerging Technologies
                  </div>{" "}
                </Link>
                <Link to="/entertainment-sports" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Entertainment, eSports, and Media
                  </div>{" "}
                </Link>
                <Link to="/financial-services" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Financial Services
                  </div>{" "}
                </Link>
                <Link to="/health-care-and-life-science" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Healthcare
                  </div>{" "}
                </Link>
                <Link to="/industrial-manufacturing" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Industry and Manufacturing
                  </div>{" "}
                </Link>
                <Link to="/infrastructure-transport" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Infrastructure and Transport
                  </div>{" "}
                </Link>
                <Link to="/pivate-equity" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Private Equity and Venture Capital
                  </div>{" "}
                </Link>
                <Link to="/real-estate" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Real Estate
                  </div>{" "}
                </Link>
                <Link to="/communication-and-media" className="textclass">
                  {" "}
                  <div className="hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    Space technologies and telecommunications
                  </div>{" "}
                </Link>
                <Link to="/white-collar-defence" className="textclass">
                  {" "}
                  <div className=" hover-underline-animation textclass font-semibold px-5  max-w-max cursor-pointer text-black m-2 rounded">
                    White Collar Defence
                  </div>{" "}
                </Link>
              </div>
            </li>
            <li className="lg:hidden block text-white py-4">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="text-xl pt-7 flex items-center justify-items-center mx-auto">
                      Sectors{" "}
                      <RiArrowRightSLine
                        className={
                          open
                            ? "rotate-90 transform w-7 h-7 mt-1"
                            : "w-7 h-7 mt-1 "
                        }
                      />
                    </Disclosure.Button>

                    <Disclosure.Panel className=" bg-[#DCDCDC]">
                      <Link to="/automotive">
                        {" "}
                        <div className="border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Automotives
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/consumer-retail">
                        {" "}
                        <div className=" border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Consumer and Retail
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/education">
                        {" "}
                        <div className=" border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Education
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC] ">
                      <Link to="/energy-and-natural-resources">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Energy and Natural Resources
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className=" bg-[#DCDCDC]">
                      <Link to="/technology">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Emerging Technologies
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/entertainment-sports">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Entertainment, eSports and Media
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/financial-services">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Financial Services
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/health-care-and-life-science">
                        {" "}
                        <div className=" border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Healthcare
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/industrial-manufacturing">
                        {" "}
                        <div className=" border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Industrials and Manufacturing
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/infrastructure-transport">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Infrastructure and Transport
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/pivate-equity">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Private Equity and Venture Capital
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/real-estate">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Real Estate
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/communication-and-media">
                        {" "}
                        <div className=" border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          Space Technologies and Telecommunications
                        </div>
                      </Link>
                    </Disclosure.Panel>
                    <Disclosure.Panel className="bg-[#DCDCDC]">
                      <Link to="/white-collar-defence">
                        {" "}
                        <div className="  border-b-2 border-[#d2b48c] text-black text-lg font-semibold py-2">
                          White Collar Defence
                        </div>
                      </Link>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </li>
            <li className="nav-item text-black hover:text-secondary cursor-pointer">
              <Link to="/team" onClick={closeMenu}>
                Team
              </Link>
            </li>
            
              <li className="nav-item text-black hover:text-secondary cursor-pointer">
              <Link to="/update-and-analysis" onClick={closeMenu}>
                Updates 
              </Link>
              </li>
              {/* <li className="nav-item text-black hover:text-secondary cursor-pointer">
              <Link to="/knowledge" onClick={closeMenu}>
                Knowledge and Analysis
              </Link>
              </li> */}

            <Link to="/join-us" onClick={closeMenu}>
              {" "}
              <li className="nav-item lg:text-black text-white hover:text-secondary  cursor-pointer md:pt-0 pt-3">
                Careers
              </li>
            </Link>
            <li className="nav-item text-black hover:text-secondary cursor-pointer">
              <Link to="/contact-us" onClick={closeMenu} className="link-color">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
